import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Adviser = {
  __typename?: 'Adviser';
  contentChannelCount: Scalars['Int'];
  contentChannels: Array<ContentChannel>;
  /**
   * Returns a course completion rate for courses started by the adviser's learners.
   *
   * The completion rate is calculated for all of the adviser's learners' courses if no arguments are given,
   * or for a subset determined by the learner, learning center, content channel, or a combination of those filters.
   */
  courseCompletionRate: CourseCompletionRate;
  courseCount: Scalars['Int'];
  learnerCount: Scalars['Int'];
  learnerInvites: Array<LearnerInvite>;
  learners: Array<User>;
  learningCenters: Array<LearningCenter>;
  topPerformingContentChannels?: Maybe<Array<ContentChannel>>;
  topPerformingCourses?: Maybe<Array<Course>>;
  topPerformingLearners?: Maybe<Array<User>>;
  user: User;
  /** Number of courses in adviser's channels completed by adviser's followers. */
  videosWatched: Scalars['Int'];
  /** Total views for all curses coming from all content channels */
  views?: Maybe<Scalars['Int']>;
};


export type AdviserCourseCompletionRateArgs = {
  contentChannelId?: InputMaybe<Scalars['ID']>;
  courseId?: InputMaybe<Scalars['ID']>;
  learnerId?: InputMaybe<Scalars['ID']>;
  learningCenterId?: InputMaybe<Scalars['ID']>;
};

export type AdviserConnectedToContentChannelEvent = {
  __typename?: 'AdviserConnectedToContentChannelEvent';
  adviser: Adviser;
  contentChannel: ContentChannel;
};

export type AdviserConnectedToLearningCenterEvent = {
  __typename?: 'AdviserConnectedToLearningCenterEvent';
  adviser: Adviser;
  learningCenter: LearningCenter;
};

export type AdviserInput = {
  learningCenterId?: InputMaybe<Scalars['ID']>;
  learningCenterName?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type AdviserInvite = Invite & {
  __typename?: 'AdviserInvite';
  id: Scalars['ID'];
  learningCenter: LearningCenter;
  recipient: User;
  sender: User;
  sentAt: Scalars['Date'];
  status: InviteStatus;
  type: InviteType;
};

export type ChannelConnectedToLearningCenterEvent = {
  __typename?: 'ChannelConnectedToLearningCenterEvent';
  contentChannel: ContentChannel;
  learningCenter: LearningCenter;
};

export type ContentChannel = {
  __typename?: 'ContentChannel';
  adviserCount: Scalars['Int'];
  advisers: Array<Adviser>;
  backgroundImage: Image;
  contentCreatorInvites: Array<ContentCreatorInvite>;
  contentCreators: Array<User>;
  /**
   * Returns a course completion rate for courses in the content channel.
   *
   * The completion rate is calculated for all courses in the content channel if no arguments are given,
   * or for a subset determined by the learner, learning center, content channel, or a combination of those filters.
   */
  courseCompletionRate: CourseCompletionRate;
  courseCount: Scalars['Int'];
  courses?: Maybe<Array<Course>>;
  hasBeenWatchedBy: Scalars['Boolean'];
  id: Scalars['ID'];
  label: CourseLabel;
  learnerCount: Scalars['Int'];
  learningCenterCount: Scalars['Int'];
  learningCenters: Array<LearningCenter>;
  logo: Image;
  name: Scalars['String'];
  ownerInvites: Array<ContentChannelOwnerInvite>;
  owners: Array<User>;
  owningLearningCenter?: Maybe<LearningCenter>;
  series: Array<Series>;
  status: ContentChannelStatus;
  topPerformingAdvisers?: Maybe<Array<Adviser>>;
  topPerformingCourses?: Maybe<Array<Course>>;
  topPerformingLearningCenters?: Maybe<Array<LearningCenter>>;
  /** Total views for all courses */
  views?: Maybe<Scalars['Int']>;
};


export type ContentChannelCourseCompletionRateArgs = {
  adviserId?: InputMaybe<Scalars['ID']>;
  courseId?: InputMaybe<Scalars['ID']>;
  learnerId?: InputMaybe<Scalars['ID']>;
  learningCenterId?: InputMaybe<Scalars['ID']>;
};


export type ContentChannelHasBeenWatchedByArgs = {
  learnerId: Scalars['ID'];
};

export type ContentChannelAdviserResponse = {
  __typename?: 'ContentChannelAdviserResponse';
  adviser?: Maybe<Adviser>;
  contentChannel?: Maybe<ContentChannel>;
};

export type ContentChannelCreatorResponse = {
  __typename?: 'ContentChannelCreatorResponse';
  contentChannel?: Maybe<ContentChannel>;
  creator?: Maybe<User>;
};

export type ContentChannelInput = {
  label?: InputMaybe<CourseLabel>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ContentChannelStatus>;
};

export type ContentChannelLearningCenterResponse = {
  __typename?: 'ContentChannelLearningCenterResponse';
  contentChannel?: Maybe<ContentChannel>;
  learningCenter?: Maybe<LearningCenter>;
};

/** User fields specific to Content Channel Owner */
export type ContentChannelOwner = {
  __typename?: 'ContentChannelOwner';
  courses: Array<Course>;
  coursesCount?: Maybe<Scalars['Int']>;
  ownedContentChannels: Array<ContentChannel>;
};

export type ContentChannelOwnerInvite = Invite & {
  __typename?: 'ContentChannelOwnerInvite';
  contentChannel: ContentChannel;
  id: Scalars['ID'];
  recipient: User;
  sender: User;
  sentAt: Scalars['Date'];
  status: InviteStatus;
  type: InviteType;
};

export enum ContentChannelStatus {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

/** User fields specific to Content Creator */
export type ContentCreator = {
  __typename?: 'ContentCreator';
  contentChannels: Array<ContentChannel>;
  courses: Array<Course>;
  coursesCount?: Maybe<Scalars['Int']>;
};

export type ContentCreatorInvite = Invite & {
  __typename?: 'ContentCreatorInvite';
  contentChannel: ContentChannel;
  id: Scalars['ID'];
  recipient: User;
  sender: User;
  sentAt: Scalars['Date'];
  status: InviteStatus;
  type: InviteType;
};

export type Course = {
  __typename?: 'Course';
  contentChannel: ContentChannel;
  /** This field is deprecated and will be removed in the future. */
  contentChannelOrder?: Maybe<Scalars['Int']>;
  /**
   * Returns a course completion rate for the course.
   *
   * The completion rate is calculated for all learners who started the course if no arguments are given,
   * or for a subset determined by the adviser, learning center, content channel, or a combination of those filters.
   */
  courseCompletionRate: CourseCompletionRate;
  creator?: Maybe<User>;
  ctaText?: Maybe<Scalars['String']>;
  ctaUrl?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  label: CourseLabel;
  learnerState?: Maybe<CourseState>;
  posterFrameUrl?: Maybe<Scalars['String']>;
  series: Series;
  /** Courses are ordered within the series according to ascending values of `seriesOrder`. */
  seriesOrder?: Maybe<Scalars['Int']>;
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
  /** This field will determine if the user prefer to use the first frame of the video as a poster image or will use the custome poster. */
  useFirstFramePoster?: Maybe<Scalars['Boolean']>;
  video?: Maybe<Video>;
  views?: Maybe<Scalars['Int']>;
};


export type CourseCourseCompletionRateArgs = {
  adviserId?: InputMaybe<Scalars['ID']>;
  contentChannelId?: InputMaybe<Scalars['ID']>;
  learnerId?: InputMaybe<Scalars['ID']>;
  learningCenterId?: InputMaybe<Scalars['ID']>;
};


export type CourseLearnerStateArgs = {
  learnerId?: InputMaybe<Scalars['ID']>;
};

export type CourseCompletionRate = {
  __typename?: 'CourseCompletionRate';
  /**
   * Percentage of finished courses among started courses.
   *
   * Null when no started courses returned for the search criteria.
   */
  completionRate?: Maybe<Scalars['Float']>;
};

export enum CourseCompletionState {
  Finished = 'FINISHED',
  Started = 'STARTED'
}

export type CourseInput = {
  ctaText?: InputMaybe<Scalars['String']>;
  ctaUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  externalUrl?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<CourseLabel>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
  useFirstFramePoster?: InputMaybe<Scalars['Boolean']>;
};

export enum CourseLabel {
  Draft = 'DRAFT',
  Live = 'LIVE'
}

export type CourseState = {
  __typename?: 'CourseState';
  completion: CourseCompletionState;
  course: Course;
  learner: User;
  timeWatchedMillis?: Maybe<Scalars['Int']>;
  watchedProgressPercentage?: Maybe<Scalars['Int']>;
};

export enum CourseStatus {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Processing = 'PROCESSING',
  WaitingForProcessing = 'WAITING_FOR_PROCESSING',
  WaitingForUpload = 'WAITING_FOR_UPLOAD'
}

export type CreateContentChannelFromInviteResponse = {
  __typename?: 'CreateContentChannelFromInviteResponse';
  contentChannel: ContentChannel;
  invite: Invite;
};

export type CreateLearningCenterFromInviteResponse = {
  __typename?: 'CreateLearningCenterFromInviteResponse';
  invite: Invite;
  learningCenter: LearningCenter;
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  roles: Array<UserRole>;
};

export type Error = {
  __typename?: 'Error';
  extensions?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type ExternalVideo = {
  __typename?: 'ExternalVideo';
  url: Scalars['String'];
};

export type HostedVideo = {
  __typename?: 'HostedVideo';
  /** Duration in the MM:SS format, or null if video still uploading / processing. */
  duration?: Maybe<Scalars['String']>;
  durationMillis?: Maybe<Scalars['Int']>;
  errorDescription?: Maybe<Scalars['String']>;
  hlsUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  mp4Url?: Maybe<Scalars['String']>;
  progressPercentageComplete?: Maybe<Scalars['Int']>;
  status: CourseStatus;
};

export type Image = {
  __typename?: 'Image';
  url?: Maybe<Scalars['String']>;
};

export type Invite = {
  id: Scalars['ID'];
  recipient: User;
  sender: User;
  sentAt: Scalars['Date'];
  status: InviteStatus;
  type: InviteType;
};

export type InviteAdvisersResponse = {
  __typename?: 'InviteAdvisersResponse';
  errors: Array<Error>;
  invites: Array<AdviserInvite>;
};

export type InviteLearnersResponse = {
  __typename?: 'InviteLearnersResponse';
  errors: Array<Error>;
  invites: Array<LearnerInvite>;
};

export type InviteRecipientInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export enum InviteStatus {
  Accepted = 'ACCEPTED',
  Bounced = 'BOUNCED',
  Error = 'ERROR',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  PendingEmailSent = 'PENDING_EMAIL_SENT',
  Rejected = 'REJECTED'
}

export enum InviteType {
  AdviserToLearningCenter = 'ADVISER_TO_LEARNING_CENTER',
  ContentChannelOwner = 'CONTENT_CHANNEL_OWNER',
  CreatorToContentChannel = 'CREATOR_TO_CONTENT_CHANNEL',
  LearnerToAdviser = 'LEARNER_TO_ADVISER',
  LearningCenterOwner = 'LEARNING_CENTER_OWNER',
  OwnerToContentChannel = 'OWNER_TO_CONTENT_CHANNEL',
  OwnerToLearningCenter = 'OWNER_TO_LEARNING_CENTER'
}

/** User fields specific to the learners */
export type Learner = {
  __typename?: 'Learner';
  advisers: Array<Adviser>;
  channelsCount: Scalars['Int'];
  contentChannels: Array<ContentChannel>;
  continueWatchingCourses: Array<Course>;
  /**
   * Returns a course completion rate for courses started by the learner.
   *
   * The completion rate is calculated for all of the learner's courses if no arguments given,
   * or for a subset determined by the adviser, learning center, content channel, or a combination of those filters.
   */
  courseCompletionRate: CourseCompletionRate;
  courses: Array<Course>;
  coursesCount: Scalars['Int'];
  videosStarted: Scalars['Int'];
  videosWatched: Scalars['Int'];
};


/** User fields specific to the learners */
export type LearnerCourseCompletionRateArgs = {
  adviserId?: InputMaybe<Scalars['ID']>;
  contentChannelId?: InputMaybe<Scalars['ID']>;
  courseId?: InputMaybe<Scalars['ID']>;
  learningCenterId?: InputMaybe<Scalars['ID']>;
};

export type LearnerAdviserResponse = {
  __typename?: 'LearnerAdviserResponse';
  adviser?: Maybe<Adviser>;
  learner?: Maybe<User>;
};

export type LearnerConnectedToAdviserEvent = {
  __typename?: 'LearnerConnectedToAdviserEvent';
  adviser: Adviser;
  learner: User;
};

export type LearnerInvite = Invite & {
  __typename?: 'LearnerInvite';
  adviser: Adviser;
  id: Scalars['ID'];
  recipient: User;
  sender: User;
  sentAt: Scalars['Date'];
  status: InviteStatus;
  type: InviteType;
};

export type LearningCenter = {
  __typename?: 'LearningCenter';
  adviserCount: Scalars['Int'];
  adviserInvites: Array<AdviserInvite>;
  advisers: Array<Adviser>;
  backgroundImage: Image;
  contentChannelCount: Scalars['Int'];
  contentChannels: Array<ContentChannel>;
  /**
   * Returns a course completion rate for courses in the learning center's content channels.
   *
   * The completion rate is calculated for all courses in learning center's content channels if no arguments are given,
   * or for a subset determined by the learner, learning center, content channel, or a combination of those filters.
   */
  courseCompletionRate: CourseCompletionRate;
  id: Scalars['ID'];
  learnerCount: Scalars['Int'];
  logo: Image;
  name: Scalars['String'];
  ownedContentChannels: Array<ContentChannel>;
  ownerInvites: Array<LearningCenterOwnerInvite>;
  owners: Array<User>;
  topPerformingAdvisers?: Maybe<Array<Adviser>>;
  topPerformingContentChannels?: Maybe<Array<ContentChannel>>;
  topPerformingCourses?: Maybe<Array<Course>>;
  /** Total views for all curses coming from all content channels */
  views?: Maybe<Scalars['Int']>;
};


export type LearningCenterCourseCompletionRateArgs = {
  adviserId?: InputMaybe<Scalars['ID']>;
  contentChannelId?: InputMaybe<Scalars['ID']>;
  courseId?: InputMaybe<Scalars['ID']>;
  learnerId?: InputMaybe<Scalars['ID']>;
};

export type LearningCenterAdviserResponse = {
  __typename?: 'LearningCenterAdviserResponse';
  adviser: Adviser;
  learningCenter: LearningCenter;
};

export type LearningCenterInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** User fields specific to Learning Center Owner */
export type LearningCenterOwner = {
  __typename?: 'LearningCenterOwner';
  ownedLearningCenters: Array<LearningCenter>;
};

export type LearningCenterOwnerInvite = Invite & {
  __typename?: 'LearningCenterOwnerInvite';
  id: Scalars['ID'];
  learningCenter: LearningCenter;
  recipient: User;
  sender: User;
  sentAt: Scalars['Date'];
  status: InviteStatus;
  type: InviteType;
};

export type LinkInvite = {
  __typename?: 'LinkInvite';
  adviser: Adviser;
  channel: ContentChannel;
  id: Scalars['ID'];
  learningCenter: LearningCenter;
};

export type LinkInviteInput = {
  adviserId: Scalars['ID'];
  contentChannelId: Scalars['ID'];
  learningCenterId: Scalars['ID'];
};

export type LinkedInInfo = {
  __typename?: 'LinkedInInfo';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePicture?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvite: Invite;
  /** Creates learning center by an admin */
  adminCreateLearningCenter?: Maybe<LearningCenter>;
  adminCreateUser?: Maybe<User>;
  connectContentChannelAdviser?: Maybe<ContentChannelAdviserResponse>;
  connectContentChannelCreator: ContentChannelCreatorResponse;
  connectContentChannelLearningCenter?: Maybe<ContentChannelLearningCenterResponse>;
  connectLearnerAdviser?: Maybe<LearnerAdviserResponse>;
  connectLearningCenterAdviser?: Maybe<LearningCenterAdviserResponse>;
  createAdviser?: Maybe<Adviser>;
  createContentChannel?: Maybe<ContentChannel>;
  createContentChannelFromInvite?: Maybe<CreateContentChannelFromInviteResponse>;
  createCourse?: Maybe<Course>;
  createLearningCenter?: Maybe<LearningCenter>;
  createLearningCenterFromInvite?: Maybe<CreateLearningCenterFromInviteResponse>;
  deleteContentChannel?: Maybe<ContentChannel>;
  deleteCourse?: Maybe<Course>;
  /** Delete invite sent to an user */
  deleteInvite: Invite;
  deleteLearningCenter?: Maybe<LearningCenter>;
  disconnectContentChannelAdviser?: Maybe<ContentChannelAdviserResponse>;
  disconnectContentChannelCreator?: Maybe<ContentChannelCreatorResponse>;
  disconnectContentChannelLearningCenter?: Maybe<ContentChannelLearningCenterResponse>;
  disconnectLearnerAdviser?: Maybe<LearnerAdviserResponse>;
  disconnectLearningCenterAdviser?: Maybe<LearningCenterAdviserResponse>;
  inviteAdvisers?: Maybe<InviteAdvisersResponse>;
  /** Sends an invitation email message and invites new or existing user to be an owner of an existing Content Channel */
  inviteContentChannelOwner?: Maybe<ContentChannelOwnerInvite>;
  /** Sends an invitation email message and invites new or existing user to become a Creator in the Content Channel */
  inviteContentCreator?: Maybe<ContentCreatorInvite>;
  inviteLearners?: Maybe<InviteLearnersResponse>;
  /** Sends an invitation email message and invites new or existing user to be an owner of an existing Learning Center */
  inviteLearningCenterOwner?: Maybe<LearningCenterOwnerInvite>;
  /** Sends an invitation email message and gives an ability for a new or existing user to create a new Content Channel - use createContentChannelFromInvite mutation to create it */
  inviteNewContentChannelOwner?: Maybe<NewContentChannelOwnerInvite>;
  /** Sends an invitation email message and gives an ability for a new or existing user to create a new Learning Center - use createLearningCenterFromInvite mutation to create it */
  inviteNewLearningCenterOwner?: Maybe<NewLearningCenterOwnerInvite>;
  linkInviteAccept: User;
  linkInviteCreate: LinkInvite;
  markNotificationAsViewed: Notification;
  /** Re-invite a user */
  reinviteMutation: Invite;
  rejectInvite: Invite;
  reorderCourses: Series;
  /** Set the course completion state for the learner to either "started" or "completed" with a time watched value. */
  sendCourseStateEvent?: Maybe<CourseState>;
  seriesCreate?: Maybe<Series>;
  seriesDelete?: Maybe<Series>;
  seriesModify?: Maybe<Series>;
  signUp?: Maybe<SignUpUser>;
  unsubscribeUser?: Maybe<Scalars['Boolean']>;
  updateContentChannel?: Maybe<ContentChannel>;
  updateCourse?: Maybe<Course>;
  updateLearningCenter?: Maybe<LearningCenter>;
  updateUser?: Maybe<User>;
  uploadVideo: UploadVideoResponse;
};


export type MutationAcceptInviteArgs = {
  id: Scalars['ID'];
};


export type MutationAdminCreateLearningCenterArgs = {
  backgroundImage?: InputMaybe<Scalars['Upload']>;
  data: LearningCenterInput;
  logo?: InputMaybe<Scalars['Upload']>;
};


export type MutationAdminCreateUserArgs = {
  data: CreateUserInput;
  picture?: InputMaybe<Scalars['Upload']>;
};


export type MutationConnectContentChannelAdviserArgs = {
  adviserId: Scalars['ID'];
  contentChannelId: Scalars['ID'];
};


export type MutationConnectContentChannelCreatorArgs = {
  contentChannelId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationConnectContentChannelLearningCenterArgs = {
  contentChannelId: Scalars['ID'];
  learningCenterId: Scalars['ID'];
};


export type MutationConnectLearnerAdviserArgs = {
  adviserId: Scalars['ID'];
  learnerId: Scalars['ID'];
};


export type MutationConnectLearningCenterAdviserArgs = {
  adviserId: Scalars['ID'];
  learningCenterId: Scalars['ID'];
};


export type MutationCreateAdviserArgs = {
  data: AdviserInput;
};


export type MutationCreateContentChannelArgs = {
  backgroundImage?: InputMaybe<Scalars['Upload']>;
  data: ContentChannelInput;
  logo?: InputMaybe<Scalars['Upload']>;
  ownerId?: InputMaybe<Scalars['ID']>;
  owningLearningCenterId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateContentChannelFromInviteArgs = {
  backgroundImage?: InputMaybe<Scalars['Upload']>;
  data: ContentChannelInput;
  inviteId: Scalars['ID'];
  logo?: InputMaybe<Scalars['Upload']>;
};


export type MutationCreateCourseArgs = {
  channelId: Scalars['ID'];
  creatorId?: InputMaybe<Scalars['ID']>;
  data: CourseInput;
  posterFrame?: InputMaybe<Scalars['Upload']>;
  seriesId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateLearningCenterArgs = {
  backgroundImage?: InputMaybe<Scalars['Upload']>;
  data: LearningCenterInput;
  logo?: InputMaybe<Scalars['Upload']>;
  ownerId: Scalars['ID'];
};


export type MutationCreateLearningCenterFromInviteArgs = {
  backgroundImage?: InputMaybe<Scalars['Upload']>;
  data: LearningCenterInput;
  inviteId: Scalars['ID'];
  logo?: InputMaybe<Scalars['Upload']>;
};


export type MutationDeleteContentChannelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCourseArgs = {
  courseId: Scalars['ID'];
};


export type MutationDeleteInviteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLearningCenterArgs = {
  id: Scalars['ID'];
};


export type MutationDisconnectContentChannelAdviserArgs = {
  adviserId: Scalars['ID'];
  contentChannelId: Scalars['ID'];
};


export type MutationDisconnectContentChannelCreatorArgs = {
  contentChannelId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationDisconnectContentChannelLearningCenterArgs = {
  contentChannelId: Scalars['ID'];
  learningCenterId: Scalars['ID'];
};


export type MutationDisconnectLearnerAdviserArgs = {
  adviserId: Scalars['ID'];
  learnerId: Scalars['ID'];
};


export type MutationDisconnectLearningCenterAdviserArgs = {
  adviserId: Scalars['ID'];
  learningCenterId: Scalars['ID'];
};


export type MutationInviteAdvisersArgs = {
  learningCenterId: Scalars['ID'];
  recipients: Array<InviteRecipientInput>;
  resend?: Scalars['Boolean'];
};


export type MutationInviteContentChannelOwnerArgs = {
  contentChannelId: Scalars['ID'];
  recipientInput: InviteRecipientInput;
};


export type MutationInviteContentCreatorArgs = {
  contentChannelId: Scalars['ID'];
  recipientInput: InviteRecipientInput;
};


export type MutationInviteLearnersArgs = {
  adviserId: Scalars['ID'];
  recipients: Array<InviteRecipientInput>;
  resend?: Scalars['Boolean'];
};


export type MutationInviteLearningCenterOwnerArgs = {
  learningCenterId: Scalars['ID'];
  recipientInput: InviteRecipientInput;
};


export type MutationInviteNewContentChannelOwnerArgs = {
  recipientInput: InviteRecipientInput;
};


export type MutationInviteNewLearningCenterOwnerArgs = {
  recipientInput: InviteRecipientInput;
};


export type MutationLinkInviteAcceptArgs = {
  inviteId: Scalars['ID'];
  learnerId: Scalars['ID'];
};


export type MutationLinkInviteCreateArgs = {
  input: LinkInviteInput;
};


export type MutationMarkNotificationAsViewedArgs = {
  id: Scalars['ID'];
};


export type MutationReinviteMutationArgs = {
  id: Scalars['ID'];
};


export type MutationRejectInviteArgs = {
  id: Scalars['ID'];
};


export type MutationReorderCoursesArgs = {
  courseIdToMove: Scalars['ID'];
  courseIdToPlaceAfter?: InputMaybe<Scalars['ID']>;
  seriesId: Scalars['ID'];
};


export type MutationSendCourseStateEventArgs = {
  completion: CourseCompletionState;
  courseId: Scalars['ID'];
  learnerId: Scalars['ID'];
  timeWatchedMillis?: InputMaybe<Scalars['Int']>;
};


export type MutationSeriesCreateArgs = {
  input: SeriesInput;
};


export type MutationSeriesDeleteArgs = {
  seriesId: Scalars['ID'];
};


export type MutationSeriesModifyArgs = {
  input: SeriesInput;
  seriesId: Scalars['ID'];
};


export type MutationSignUpArgs = {
  data: SignUpInput;
  picture?: InputMaybe<Scalars['Upload']>;
};


export type MutationUnsubscribeUserArgs = {
  id: UserId;
  resubscribe: Scalars['Boolean'];
  secret: Scalars['String'];
};


export type MutationUpdateContentChannelArgs = {
  backgroundImage?: InputMaybe<Scalars['Upload']>;
  data: ContentChannelInput;
  id: Scalars['ID'];
  logo?: InputMaybe<Scalars['Upload']>;
};


export type MutationUpdateCourseArgs = {
  contentChannelId?: InputMaybe<Scalars['ID']>;
  courseId: Scalars['ID'];
  input?: InputMaybe<CourseInput>;
  posterFrame?: InputMaybe<Scalars['Upload']>;
  seriesId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateLearningCenterArgs = {
  backgroundImage?: InputMaybe<Scalars['Upload']>;
  data: LearningCenterInput;
  id: Scalars['ID'];
  logo?: InputMaybe<Scalars['Upload']>;
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
  id: UserId;
  picture?: InputMaybe<Scalars['Upload']>;
};


export type MutationUploadVideoArgs = {
  courseId: Scalars['ID'];
  filename: Scalars['String'];
};

export type NewChannelVideoEvent = {
  __typename?: 'NewChannelVideoEvent';
  contentChannel: ContentChannel;
  course: Course;
};

export type NewContentChannelOwnerInvite = Invite & {
  __typename?: 'NewContentChannelOwnerInvite';
  id: Scalars['ID'];
  recipient: User;
  sender: User;
  sentAt: Scalars['Date'];
  status: InviteStatus;
  type: InviteType;
};

export type NewLearningCenterEvent = {
  __typename?: 'NewLearningCenterEvent';
  learningCenter: LearningCenter;
};

export type NewLearningCenterOwnerInvite = Invite & {
  __typename?: 'NewLearningCenterOwnerInvite';
  id: Scalars['ID'];
  recipient: User;
  sender: User;
  sentAt: Scalars['Date'];
  status: InviteStatus;
  type: InviteType;
};

export type NewLiveChannelEvent = {
  __typename?: 'NewLiveChannelEvent';
  contentChannel: ContentChannel;
};

export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['Date'];
  data: NotificationData;
  id: Scalars['ID'];
  recipient: User;
  viewed: Scalars['Boolean'];
};

export type NotificationData = AdviserConnectedToContentChannelEvent | AdviserConnectedToLearningCenterEvent | ChannelConnectedToLearningCenterEvent | LearnerConnectedToAdviserEvent | NewChannelVideoEvent | NewLearningCenterEvent | NewLiveChannelEvent | NotificationInvite;

export type NotificationInvite = {
  __typename?: 'NotificationInvite';
  invite: Invite;
};

export type PublicUserInfo = {
  __typename?: 'PublicUserInfo';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  subscribedToEmail?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  adviser?: Maybe<Adviser>;
  contentChannel?: Maybe<ContentChannel>;
  contentChannelsAll?: Maybe<Array<ContentChannel>>;
  contentChannelsBy?: Maybe<Array<ContentChannel>>;
  contentChannelsPublic?: Maybe<Array<ContentChannel>>;
  course?: Maybe<Course>;
  getLinkedInInfo?: Maybe<LinkedInInfo>;
  invite?: Maybe<Invite>;
  invites: Array<Invite>;
  learner?: Maybe<Learner>;
  /** Learning Center queried by id, name or slug */
  learningCenter?: Maybe<LearningCenter>;
  learningCenters: Array<LearningCenter>;
  linkInvite?: Maybe<LinkInvite>;
  series?: Maybe<Series>;
  unauthenticatedLinkInvite?: Maybe<UnauthenticatedLinkInvite>;
  user?: Maybe<User>;
  userInfo?: Maybe<PublicUserInfo>;
};


export type QueryAdviserArgs = {
  slug?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<UserId>;
};


export type QueryContentChannelArgs = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryContentChannelsByArgs = {
  adviserId?: InputMaybe<Scalars['ID']>;
  learningCenterId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type QueryCourseArgs = {
  id: Scalars['ID'];
};


export type QueryGetLinkedInInfoArgs = {
  code: Scalars['String'];
};


export type QueryInviteArgs = {
  id: Scalars['ID'];
};


export type QueryInvitesArgs = {
  recipientId?: InputMaybe<UserId>;
  senderId?: InputMaybe<UserId>;
  status?: InputMaybe<InviteStatus>;
  type?: InputMaybe<InviteType>;
};


export type QueryLearnerArgs = {
  userId: UserId;
};


export type QueryLearningCenterArgs = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryLinkInviteArgs = {
  id: Scalars['ID'];
};


export type QuerySeriesArgs = {
  id: Scalars['ID'];
};


export type QueryUnauthenticatedLinkInviteArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id: UserId;
};


export type QueryUserInfoArgs = {
  id: UserId;
};

export type Series = {
  __typename?: 'Series';
  channel: ContentChannel;
  /**
   * Returns a course completion rate for courses in the series.
   *
   * The completion rate is calculated for all courses in the series if no arguments are given,
   * or for a subset determined by the learner, learning center, adviser, or a combination of those filters.
   */
  courseCompletionRate: CourseCompletionRate;
  courses: Array<Course>;
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  /** Total views for courses in series */
  views?: Maybe<Scalars['Int']>;
};


export type SeriesCourseCompletionRateArgs = {
  adviserId?: InputMaybe<Scalars['ID']>;
  learnerId?: InputMaybe<Scalars['ID']>;
  learningCenterId?: InputMaybe<Scalars['ID']>;
};

export type SeriesInput = {
  channelId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SignUpInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: UserRole;
};

export type SignUpUser = {
  __typename?: 'SignUpUser';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  identityProviderId: Scalars['String'];
  lastName: Scalars['String'];
  role: UserRole;
  status: UserStatus;
};

export type UnauthenticatedLinkInvite = {
  __typename?: 'UnauthenticatedLinkInvite';
  adviserId: Scalars['ID'];
  adviserName: Scalars['String'];
  contentChannelId: Scalars['ID'];
  contentChannelName: Scalars['String'];
  id: Scalars['ID'];
  learningCenterId: Scalars['ID'];
  learningCenterName: Scalars['String'];
};

export type UpdateUserInput = {
  bio?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  recoveryEmail?: InputMaybe<Scalars['String']>;
};

export type UploadVideoResponse = {
  __typename?: 'UploadVideoResponse';
  signedUrl: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  adviser?: Maybe<Adviser>;
  bio?: Maybe<Scalars['String']>;
  contentChannelOwner?: Maybe<ContentChannelOwner>;
  contentCreator?: Maybe<ContentCreator>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identityProviderId?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  learner?: Maybe<Learner>;
  learningCenterOwner?: Maybe<LearningCenterOwner>;
  linkedinUrl?: Maybe<Scalars['String']>;
  notifications: Array<Notification>;
  organization?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  profileImage: Image;
  receivedInvites: Array<Invite>;
  recoveryEmail?: Maybe<Scalars['String']>;
  roles: Array<UserRoles>;
  sentInvites: Array<Invite>;
  status: UserStatus;
  subscribedToEmail?: Maybe<Scalars['Boolean']>;
};

export type UserId = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  identityProviderId?: InputMaybe<Scalars['ID']>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Adviser = 'ADVISER',
  ContentChannelOwner = 'CONTENT_CHANNEL_OWNER',
  ContentCreator = 'CONTENT_CREATOR',
  Learner = 'LEARNER',
  LearningCenterOwner = 'LEARNING_CENTER_OWNER'
}

export type UserRoles = {
  __typename?: 'UserRoles';
  id: Scalars['ID'];
  role: UserRole;
  userId: Scalars['ID'];
};

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type Video = ExternalVideo | HostedVideo;



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Adviser: ResolverTypeWrapper<Adviser>;
  AdviserConnectedToContentChannelEvent: ResolverTypeWrapper<AdviserConnectedToContentChannelEvent>;
  AdviserConnectedToLearningCenterEvent: ResolverTypeWrapper<AdviserConnectedToLearningCenterEvent>;
  AdviserInput: AdviserInput;
  AdviserInvite: ResolverTypeWrapper<AdviserInvite>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  ChannelConnectedToLearningCenterEvent: ResolverTypeWrapper<ChannelConnectedToLearningCenterEvent>;
  ContentChannel: ResolverTypeWrapper<ContentChannel>;
  ContentChannelAdviserResponse: ResolverTypeWrapper<ContentChannelAdviserResponse>;
  ContentChannelCreatorResponse: ResolverTypeWrapper<ContentChannelCreatorResponse>;
  ContentChannelInput: ContentChannelInput;
  ContentChannelLearningCenterResponse: ResolverTypeWrapper<ContentChannelLearningCenterResponse>;
  ContentChannelOwner: ResolverTypeWrapper<ContentChannelOwner>;
  ContentChannelOwnerInvite: ResolverTypeWrapper<ContentChannelOwnerInvite>;
  ContentChannelStatus: ContentChannelStatus;
  ContentCreator: ResolverTypeWrapper<ContentCreator>;
  ContentCreatorInvite: ResolverTypeWrapper<ContentCreatorInvite>;
  Course: ResolverTypeWrapper<Omit<Course, 'video'> & { video?: Maybe<ResolversTypes['Video']> }>;
  CourseCompletionRate: ResolverTypeWrapper<CourseCompletionRate>;
  CourseCompletionState: CourseCompletionState;
  CourseInput: CourseInput;
  CourseLabel: CourseLabel;
  CourseState: ResolverTypeWrapper<CourseState>;
  CourseStatus: CourseStatus;
  CreateContentChannelFromInviteResponse: ResolverTypeWrapper<CreateContentChannelFromInviteResponse>;
  CreateLearningCenterFromInviteResponse: ResolverTypeWrapper<CreateLearningCenterFromInviteResponse>;
  CreateUserInput: CreateUserInput;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  Error: ResolverTypeWrapper<Error>;
  ExternalVideo: ResolverTypeWrapper<ExternalVideo>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  HostedVideo: ResolverTypeWrapper<HostedVideo>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Image: ResolverTypeWrapper<Image>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Invite: ResolversTypes['AdviserInvite'] | ResolversTypes['ContentChannelOwnerInvite'] | ResolversTypes['ContentCreatorInvite'] | ResolversTypes['LearnerInvite'] | ResolversTypes['LearningCenterOwnerInvite'] | ResolversTypes['NewContentChannelOwnerInvite'] | ResolversTypes['NewLearningCenterOwnerInvite'];
  InviteAdvisersResponse: ResolverTypeWrapper<InviteAdvisersResponse>;
  InviteLearnersResponse: ResolverTypeWrapper<InviteLearnersResponse>;
  InviteRecipientInput: InviteRecipientInput;
  InviteStatus: InviteStatus;
  InviteType: InviteType;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  Learner: ResolverTypeWrapper<Learner>;
  LearnerAdviserResponse: ResolverTypeWrapper<LearnerAdviserResponse>;
  LearnerConnectedToAdviserEvent: ResolverTypeWrapper<LearnerConnectedToAdviserEvent>;
  LearnerInvite: ResolverTypeWrapper<LearnerInvite>;
  LearningCenter: ResolverTypeWrapper<LearningCenter>;
  LearningCenterAdviserResponse: ResolverTypeWrapper<LearningCenterAdviserResponse>;
  LearningCenterInput: LearningCenterInput;
  LearningCenterOwner: ResolverTypeWrapper<LearningCenterOwner>;
  LearningCenterOwnerInvite: ResolverTypeWrapper<LearningCenterOwnerInvite>;
  LinkInvite: ResolverTypeWrapper<LinkInvite>;
  LinkInviteInput: LinkInviteInput;
  LinkedInInfo: ResolverTypeWrapper<LinkedInInfo>;
  Mutation: ResolverTypeWrapper<{}>;
  NewChannelVideoEvent: ResolverTypeWrapper<NewChannelVideoEvent>;
  NewContentChannelOwnerInvite: ResolverTypeWrapper<NewContentChannelOwnerInvite>;
  NewLearningCenterEvent: ResolverTypeWrapper<NewLearningCenterEvent>;
  NewLearningCenterOwnerInvite: ResolverTypeWrapper<NewLearningCenterOwnerInvite>;
  NewLiveChannelEvent: ResolverTypeWrapper<NewLiveChannelEvent>;
  Notification: ResolverTypeWrapper<Omit<Notification, 'data'> & { data: ResolversTypes['NotificationData'] }>;
  NotificationData: ResolversTypes['AdviserConnectedToContentChannelEvent'] | ResolversTypes['AdviserConnectedToLearningCenterEvent'] | ResolversTypes['ChannelConnectedToLearningCenterEvent'] | ResolversTypes['LearnerConnectedToAdviserEvent'] | ResolversTypes['NewChannelVideoEvent'] | ResolversTypes['NewLearningCenterEvent'] | ResolversTypes['NewLiveChannelEvent'] | ResolversTypes['NotificationInvite'];
  NotificationInvite: ResolverTypeWrapper<NotificationInvite>;
  PublicUserInfo: ResolverTypeWrapper<PublicUserInfo>;
  Query: ResolverTypeWrapper<{}>;
  Series: ResolverTypeWrapper<Series>;
  SeriesInput: SeriesInput;
  SignUpInput: SignUpInput;
  SignUpUser: ResolverTypeWrapper<SignUpUser>;
  String: ResolverTypeWrapper<Scalars['String']>;
  UnauthenticatedLinkInvite: ResolverTypeWrapper<UnauthenticatedLinkInvite>;
  UpdateUserInput: UpdateUserInput;
  Upload: ResolverTypeWrapper<Scalars['Upload']>;
  UploadVideoResponse: ResolverTypeWrapper<UploadVideoResponse>;
  User: ResolverTypeWrapper<User>;
  UserId: UserId;
  UserRole: UserRole;
  UserRoles: ResolverTypeWrapper<UserRoles>;
  UserStatus: UserStatus;
  Video: ResolversTypes['ExternalVideo'] | ResolversTypes['HostedVideo'];
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Adviser: Adviser;
  AdviserConnectedToContentChannelEvent: AdviserConnectedToContentChannelEvent;
  AdviserConnectedToLearningCenterEvent: AdviserConnectedToLearningCenterEvent;
  AdviserInput: AdviserInput;
  AdviserInvite: AdviserInvite;
  Boolean: Scalars['Boolean'];
  ChannelConnectedToLearningCenterEvent: ChannelConnectedToLearningCenterEvent;
  ContentChannel: ContentChannel;
  ContentChannelAdviserResponse: ContentChannelAdviserResponse;
  ContentChannelCreatorResponse: ContentChannelCreatorResponse;
  ContentChannelInput: ContentChannelInput;
  ContentChannelLearningCenterResponse: ContentChannelLearningCenterResponse;
  ContentChannelOwner: ContentChannelOwner;
  ContentChannelOwnerInvite: ContentChannelOwnerInvite;
  ContentCreator: ContentCreator;
  ContentCreatorInvite: ContentCreatorInvite;
  Course: Omit<Course, 'video'> & { video?: Maybe<ResolversParentTypes['Video']> };
  CourseCompletionRate: CourseCompletionRate;
  CourseInput: CourseInput;
  CourseState: CourseState;
  CreateContentChannelFromInviteResponse: CreateContentChannelFromInviteResponse;
  CreateLearningCenterFromInviteResponse: CreateLearningCenterFromInviteResponse;
  CreateUserInput: CreateUserInput;
  Date: Scalars['Date'];
  Error: Error;
  ExternalVideo: ExternalVideo;
  Float: Scalars['Float'];
  HostedVideo: HostedVideo;
  ID: Scalars['ID'];
  Image: Image;
  Int: Scalars['Int'];
  Invite: ResolversParentTypes['AdviserInvite'] | ResolversParentTypes['ContentChannelOwnerInvite'] | ResolversParentTypes['ContentCreatorInvite'] | ResolversParentTypes['LearnerInvite'] | ResolversParentTypes['LearningCenterOwnerInvite'] | ResolversParentTypes['NewContentChannelOwnerInvite'] | ResolversParentTypes['NewLearningCenterOwnerInvite'];
  InviteAdvisersResponse: InviteAdvisersResponse;
  InviteLearnersResponse: InviteLearnersResponse;
  InviteRecipientInput: InviteRecipientInput;
  JSON: Scalars['JSON'];
  Learner: Learner;
  LearnerAdviserResponse: LearnerAdviserResponse;
  LearnerConnectedToAdviserEvent: LearnerConnectedToAdviserEvent;
  LearnerInvite: LearnerInvite;
  LearningCenter: LearningCenter;
  LearningCenterAdviserResponse: LearningCenterAdviserResponse;
  LearningCenterInput: LearningCenterInput;
  LearningCenterOwner: LearningCenterOwner;
  LearningCenterOwnerInvite: LearningCenterOwnerInvite;
  LinkInvite: LinkInvite;
  LinkInviteInput: LinkInviteInput;
  LinkedInInfo: LinkedInInfo;
  Mutation: {};
  NewChannelVideoEvent: NewChannelVideoEvent;
  NewContentChannelOwnerInvite: NewContentChannelOwnerInvite;
  NewLearningCenterEvent: NewLearningCenterEvent;
  NewLearningCenterOwnerInvite: NewLearningCenterOwnerInvite;
  NewLiveChannelEvent: NewLiveChannelEvent;
  Notification: Omit<Notification, 'data'> & { data: ResolversParentTypes['NotificationData'] };
  NotificationData: ResolversParentTypes['AdviserConnectedToContentChannelEvent'] | ResolversParentTypes['AdviserConnectedToLearningCenterEvent'] | ResolversParentTypes['ChannelConnectedToLearningCenterEvent'] | ResolversParentTypes['LearnerConnectedToAdviserEvent'] | ResolversParentTypes['NewChannelVideoEvent'] | ResolversParentTypes['NewLearningCenterEvent'] | ResolversParentTypes['NewLiveChannelEvent'] | ResolversParentTypes['NotificationInvite'];
  NotificationInvite: NotificationInvite;
  PublicUserInfo: PublicUserInfo;
  Query: {};
  Series: Series;
  SeriesInput: SeriesInput;
  SignUpInput: SignUpInput;
  SignUpUser: SignUpUser;
  String: Scalars['String'];
  UnauthenticatedLinkInvite: UnauthenticatedLinkInvite;
  UpdateUserInput: UpdateUserInput;
  Upload: Scalars['Upload'];
  UploadVideoResponse: UploadVideoResponse;
  User: User;
  UserId: UserId;
  UserRoles: UserRoles;
  Video: ResolversParentTypes['ExternalVideo'] | ResolversParentTypes['HostedVideo'];
};

export type AdviserResolvers<ContextType = any, ParentType extends ResolversParentTypes['Adviser'] = ResolversParentTypes['Adviser']> = {
  contentChannelCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  contentChannels?: Resolver<Array<ResolversTypes['ContentChannel']>, ParentType, ContextType>;
  courseCompletionRate?: Resolver<ResolversTypes['CourseCompletionRate'], ParentType, ContextType, RequireFields<AdviserCourseCompletionRateArgs, never>>;
  courseCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  learnerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  learnerInvites?: Resolver<Array<ResolversTypes['LearnerInvite']>, ParentType, ContextType>;
  learners?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  learningCenters?: Resolver<Array<ResolversTypes['LearningCenter']>, ParentType, ContextType>;
  topPerformingContentChannels?: Resolver<Maybe<Array<ResolversTypes['ContentChannel']>>, ParentType, ContextType>;
  topPerformingCourses?: Resolver<Maybe<Array<ResolversTypes['Course']>>, ParentType, ContextType>;
  topPerformingLearners?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  videosWatched?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdviserConnectedToContentChannelEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdviserConnectedToContentChannelEvent'] = ResolversParentTypes['AdviserConnectedToContentChannelEvent']> = {
  adviser?: Resolver<ResolversTypes['Adviser'], ParentType, ContextType>;
  contentChannel?: Resolver<ResolversTypes['ContentChannel'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdviserConnectedToLearningCenterEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdviserConnectedToLearningCenterEvent'] = ResolversParentTypes['AdviserConnectedToLearningCenterEvent']> = {
  adviser?: Resolver<ResolversTypes['Adviser'], ParentType, ContextType>;
  learningCenter?: Resolver<ResolversTypes['LearningCenter'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdviserInviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdviserInvite'] = ResolversParentTypes['AdviserInvite']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  learningCenter?: Resolver<ResolversTypes['LearningCenter'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  sentAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InviteStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['InviteType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelConnectedToLearningCenterEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelConnectedToLearningCenterEvent'] = ResolversParentTypes['ChannelConnectedToLearningCenterEvent']> = {
  contentChannel?: Resolver<ResolversTypes['ContentChannel'], ParentType, ContextType>;
  learningCenter?: Resolver<ResolversTypes['LearningCenter'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentChannelResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentChannel'] = ResolversParentTypes['ContentChannel']> = {
  adviserCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  advisers?: Resolver<Array<ResolversTypes['Adviser']>, ParentType, ContextType>;
  backgroundImage?: Resolver<ResolversTypes['Image'], ParentType, ContextType>;
  contentCreatorInvites?: Resolver<Array<ResolversTypes['ContentCreatorInvite']>, ParentType, ContextType>;
  contentCreators?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  courseCompletionRate?: Resolver<ResolversTypes['CourseCompletionRate'], ParentType, ContextType, RequireFields<ContentChannelCourseCompletionRateArgs, never>>;
  courseCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  courses?: Resolver<Maybe<Array<ResolversTypes['Course']>>, ParentType, ContextType>;
  hasBeenWatchedBy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<ContentChannelHasBeenWatchedByArgs, 'learnerId'>>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['CourseLabel'], ParentType, ContextType>;
  learnerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  learningCenterCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  learningCenters?: Resolver<Array<ResolversTypes['LearningCenter']>, ParentType, ContextType>;
  logo?: Resolver<ResolversTypes['Image'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerInvites?: Resolver<Array<ResolversTypes['ContentChannelOwnerInvite']>, ParentType, ContextType>;
  owners?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  owningLearningCenter?: Resolver<Maybe<ResolversTypes['LearningCenter']>, ParentType, ContextType>;
  series?: Resolver<Array<ResolversTypes['Series']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ContentChannelStatus'], ParentType, ContextType>;
  topPerformingAdvisers?: Resolver<Maybe<Array<ResolversTypes['Adviser']>>, ParentType, ContextType>;
  topPerformingCourses?: Resolver<Maybe<Array<ResolversTypes['Course']>>, ParentType, ContextType>;
  topPerformingLearningCenters?: Resolver<Maybe<Array<ResolversTypes['LearningCenter']>>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentChannelAdviserResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentChannelAdviserResponse'] = ResolversParentTypes['ContentChannelAdviserResponse']> = {
  adviser?: Resolver<Maybe<ResolversTypes['Adviser']>, ParentType, ContextType>;
  contentChannel?: Resolver<Maybe<ResolversTypes['ContentChannel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentChannelCreatorResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentChannelCreatorResponse'] = ResolversParentTypes['ContentChannelCreatorResponse']> = {
  contentChannel?: Resolver<Maybe<ResolversTypes['ContentChannel']>, ParentType, ContextType>;
  creator?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentChannelLearningCenterResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentChannelLearningCenterResponse'] = ResolversParentTypes['ContentChannelLearningCenterResponse']> = {
  contentChannel?: Resolver<Maybe<ResolversTypes['ContentChannel']>, ParentType, ContextType>;
  learningCenter?: Resolver<Maybe<ResolversTypes['LearningCenter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentChannelOwnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentChannelOwner'] = ResolversParentTypes['ContentChannelOwner']> = {
  courses?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType>;
  coursesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownedContentChannels?: Resolver<Array<ResolversTypes['ContentChannel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentChannelOwnerInviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentChannelOwnerInvite'] = ResolversParentTypes['ContentChannelOwnerInvite']> = {
  contentChannel?: Resolver<ResolversTypes['ContentChannel'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  sentAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InviteStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['InviteType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentCreatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentCreator'] = ResolversParentTypes['ContentCreator']> = {
  contentChannels?: Resolver<Array<ResolversTypes['ContentChannel']>, ParentType, ContextType>;
  courses?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType>;
  coursesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentCreatorInviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentCreatorInvite'] = ResolversParentTypes['ContentCreatorInvite']> = {
  contentChannel?: Resolver<ResolversTypes['ContentChannel'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  sentAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InviteStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['InviteType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Course'] = ResolversParentTypes['Course']> = {
  contentChannel?: Resolver<ResolversTypes['ContentChannel'], ParentType, ContextType>;
  contentChannelOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  courseCompletionRate?: Resolver<ResolversTypes['CourseCompletionRate'], ParentType, ContextType, RequireFields<CourseCourseCompletionRateArgs, never>>;
  creator?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  ctaText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ctaUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['CourseLabel'], ParentType, ContextType>;
  learnerState?: Resolver<Maybe<ResolversTypes['CourseState']>, ParentType, ContextType, RequireFields<CourseLearnerStateArgs, never>>;
  posterFrameUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  series?: Resolver<ResolversTypes['Series'], ParentType, ContextType>;
  seriesOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  useFirstFramePoster?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  video?: Resolver<Maybe<ResolversTypes['Video']>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseCompletionRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseCompletionRate'] = ResolversParentTypes['CourseCompletionRate']> = {
  completionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseState'] = ResolversParentTypes['CourseState']> = {
  completion?: Resolver<ResolversTypes['CourseCompletionState'], ParentType, ContextType>;
  course?: Resolver<ResolversTypes['Course'], ParentType, ContextType>;
  learner?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  timeWatchedMillis?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  watchedProgressPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateContentChannelFromInviteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateContentChannelFromInviteResponse'] = ResolversParentTypes['CreateContentChannelFromInviteResponse']> = {
  contentChannel?: Resolver<ResolversTypes['ContentChannel'], ParentType, ContextType>;
  invite?: Resolver<ResolversTypes['Invite'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateLearningCenterFromInviteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateLearningCenterFromInviteResponse'] = ResolversParentTypes['CreateLearningCenterFromInviteResponse']> = {
  invite?: Resolver<ResolversTypes['Invite'], ParentType, ContextType>;
  learningCenter?: Resolver<ResolversTypes['LearningCenter'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type ErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Error'] = ResolversParentTypes['Error']> = {
  extensions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalVideoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalVideo'] = ResolversParentTypes['ExternalVideo']> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HostedVideoResolvers<ContextType = any, ParentType extends ResolversParentTypes['HostedVideo'] = ResolversParentTypes['HostedVideo']> = {
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  durationMillis?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  errorDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hlsUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  mp4Url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  progressPercentageComplete?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CourseStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']> = {
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['Invite'] = ResolversParentTypes['Invite']> = {
  __resolveType: TypeResolveFn<'AdviserInvite' | 'ContentChannelOwnerInvite' | 'ContentCreatorInvite' | 'LearnerInvite' | 'LearningCenterOwnerInvite' | 'NewContentChannelOwnerInvite' | 'NewLearningCenterOwnerInvite', ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  sentAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InviteStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['InviteType'], ParentType, ContextType>;
};

export type InviteAdvisersResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteAdvisersResponse'] = ResolversParentTypes['InviteAdvisersResponse']> = {
  errors?: Resolver<Array<ResolversTypes['Error']>, ParentType, ContextType>;
  invites?: Resolver<Array<ResolversTypes['AdviserInvite']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteLearnersResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteLearnersResponse'] = ResolversParentTypes['InviteLearnersResponse']> = {
  errors?: Resolver<Array<ResolversTypes['Error']>, ParentType, ContextType>;
  invites?: Resolver<Array<ResolversTypes['LearnerInvite']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type LearnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Learner'] = ResolversParentTypes['Learner']> = {
  advisers?: Resolver<Array<ResolversTypes['Adviser']>, ParentType, ContextType>;
  channelsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  contentChannels?: Resolver<Array<ResolversTypes['ContentChannel']>, ParentType, ContextType>;
  continueWatchingCourses?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType>;
  courseCompletionRate?: Resolver<ResolversTypes['CourseCompletionRate'], ParentType, ContextType, RequireFields<LearnerCourseCompletionRateArgs, never>>;
  courses?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType>;
  coursesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videosStarted?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videosWatched?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LearnerAdviserResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LearnerAdviserResponse'] = ResolversParentTypes['LearnerAdviserResponse']> = {
  adviser?: Resolver<Maybe<ResolversTypes['Adviser']>, ParentType, ContextType>;
  learner?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LearnerConnectedToAdviserEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['LearnerConnectedToAdviserEvent'] = ResolversParentTypes['LearnerConnectedToAdviserEvent']> = {
  adviser?: Resolver<ResolversTypes['Adviser'], ParentType, ContextType>;
  learner?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LearnerInviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['LearnerInvite'] = ResolversParentTypes['LearnerInvite']> = {
  adviser?: Resolver<ResolversTypes['Adviser'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  sentAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InviteStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['InviteType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LearningCenterResolvers<ContextType = any, ParentType extends ResolversParentTypes['LearningCenter'] = ResolversParentTypes['LearningCenter']> = {
  adviserCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  adviserInvites?: Resolver<Array<ResolversTypes['AdviserInvite']>, ParentType, ContextType>;
  advisers?: Resolver<Array<ResolversTypes['Adviser']>, ParentType, ContextType>;
  backgroundImage?: Resolver<ResolversTypes['Image'], ParentType, ContextType>;
  contentChannelCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  contentChannels?: Resolver<Array<ResolversTypes['ContentChannel']>, ParentType, ContextType>;
  courseCompletionRate?: Resolver<ResolversTypes['CourseCompletionRate'], ParentType, ContextType, RequireFields<LearningCenterCourseCompletionRateArgs, never>>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  learnerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  logo?: Resolver<ResolversTypes['Image'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownedContentChannels?: Resolver<Array<ResolversTypes['ContentChannel']>, ParentType, ContextType>;
  ownerInvites?: Resolver<Array<ResolversTypes['LearningCenterOwnerInvite']>, ParentType, ContextType>;
  owners?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  topPerformingAdvisers?: Resolver<Maybe<Array<ResolversTypes['Adviser']>>, ParentType, ContextType>;
  topPerformingContentChannels?: Resolver<Maybe<Array<ResolversTypes['ContentChannel']>>, ParentType, ContextType>;
  topPerformingCourses?: Resolver<Maybe<Array<ResolversTypes['Course']>>, ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LearningCenterAdviserResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LearningCenterAdviserResponse'] = ResolversParentTypes['LearningCenterAdviserResponse']> = {
  adviser?: Resolver<ResolversTypes['Adviser'], ParentType, ContextType>;
  learningCenter?: Resolver<ResolversTypes['LearningCenter'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LearningCenterOwnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['LearningCenterOwner'] = ResolversParentTypes['LearningCenterOwner']> = {
  ownedLearningCenters?: Resolver<Array<ResolversTypes['LearningCenter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LearningCenterOwnerInviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['LearningCenterOwnerInvite'] = ResolversParentTypes['LearningCenterOwnerInvite']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  learningCenter?: Resolver<ResolversTypes['LearningCenter'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  sentAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InviteStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['InviteType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkInviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['LinkInvite'] = ResolversParentTypes['LinkInvite']> = {
  adviser?: Resolver<ResolversTypes['Adviser'], ParentType, ContextType>;
  channel?: Resolver<ResolversTypes['ContentChannel'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  learningCenter?: Resolver<ResolversTypes['LearningCenter'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkedInInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['LinkedInInfo'] = ResolversParentTypes['LinkedInInfo']> = {
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profilePicture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  acceptInvite?: Resolver<ResolversTypes['Invite'], ParentType, ContextType, RequireFields<MutationAcceptInviteArgs, 'id'>>;
  adminCreateLearningCenter?: Resolver<Maybe<ResolversTypes['LearningCenter']>, ParentType, ContextType, RequireFields<MutationAdminCreateLearningCenterArgs, 'data'>>;
  adminCreateUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationAdminCreateUserArgs, 'data'>>;
  connectContentChannelAdviser?: Resolver<Maybe<ResolversTypes['ContentChannelAdviserResponse']>, ParentType, ContextType, RequireFields<MutationConnectContentChannelAdviserArgs, 'adviserId' | 'contentChannelId'>>;
  connectContentChannelCreator?: Resolver<ResolversTypes['ContentChannelCreatorResponse'], ParentType, ContextType, RequireFields<MutationConnectContentChannelCreatorArgs, 'contentChannelId' | 'userId'>>;
  connectContentChannelLearningCenter?: Resolver<Maybe<ResolversTypes['ContentChannelLearningCenterResponse']>, ParentType, ContextType, RequireFields<MutationConnectContentChannelLearningCenterArgs, 'contentChannelId' | 'learningCenterId'>>;
  connectLearnerAdviser?: Resolver<Maybe<ResolversTypes['LearnerAdviserResponse']>, ParentType, ContextType, RequireFields<MutationConnectLearnerAdviserArgs, 'adviserId' | 'learnerId'>>;
  connectLearningCenterAdviser?: Resolver<Maybe<ResolversTypes['LearningCenterAdviserResponse']>, ParentType, ContextType, RequireFields<MutationConnectLearningCenterAdviserArgs, 'adviserId' | 'learningCenterId'>>;
  createAdviser?: Resolver<Maybe<ResolversTypes['Adviser']>, ParentType, ContextType, RequireFields<MutationCreateAdviserArgs, 'data'>>;
  createContentChannel?: Resolver<Maybe<ResolversTypes['ContentChannel']>, ParentType, ContextType, RequireFields<MutationCreateContentChannelArgs, 'data'>>;
  createContentChannelFromInvite?: Resolver<Maybe<ResolversTypes['CreateContentChannelFromInviteResponse']>, ParentType, ContextType, RequireFields<MutationCreateContentChannelFromInviteArgs, 'data' | 'inviteId'>>;
  createCourse?: Resolver<Maybe<ResolversTypes['Course']>, ParentType, ContextType, RequireFields<MutationCreateCourseArgs, 'channelId' | 'data'>>;
  createLearningCenter?: Resolver<Maybe<ResolversTypes['LearningCenter']>, ParentType, ContextType, RequireFields<MutationCreateLearningCenterArgs, 'data' | 'ownerId'>>;
  createLearningCenterFromInvite?: Resolver<Maybe<ResolversTypes['CreateLearningCenterFromInviteResponse']>, ParentType, ContextType, RequireFields<MutationCreateLearningCenterFromInviteArgs, 'data' | 'inviteId'>>;
  deleteContentChannel?: Resolver<Maybe<ResolversTypes['ContentChannel']>, ParentType, ContextType, RequireFields<MutationDeleteContentChannelArgs, 'id'>>;
  deleteCourse?: Resolver<Maybe<ResolversTypes['Course']>, ParentType, ContextType, RequireFields<MutationDeleteCourseArgs, 'courseId'>>;
  deleteInvite?: Resolver<ResolversTypes['Invite'], ParentType, ContextType, RequireFields<MutationDeleteInviteArgs, 'id'>>;
  deleteLearningCenter?: Resolver<Maybe<ResolversTypes['LearningCenter']>, ParentType, ContextType, RequireFields<MutationDeleteLearningCenterArgs, 'id'>>;
  disconnectContentChannelAdviser?: Resolver<Maybe<ResolversTypes['ContentChannelAdviserResponse']>, ParentType, ContextType, RequireFields<MutationDisconnectContentChannelAdviserArgs, 'adviserId' | 'contentChannelId'>>;
  disconnectContentChannelCreator?: Resolver<Maybe<ResolversTypes['ContentChannelCreatorResponse']>, ParentType, ContextType, RequireFields<MutationDisconnectContentChannelCreatorArgs, 'contentChannelId' | 'userId'>>;
  disconnectContentChannelLearningCenter?: Resolver<Maybe<ResolversTypes['ContentChannelLearningCenterResponse']>, ParentType, ContextType, RequireFields<MutationDisconnectContentChannelLearningCenterArgs, 'contentChannelId' | 'learningCenterId'>>;
  disconnectLearnerAdviser?: Resolver<Maybe<ResolversTypes['LearnerAdviserResponse']>, ParentType, ContextType, RequireFields<MutationDisconnectLearnerAdviserArgs, 'adviserId' | 'learnerId'>>;
  disconnectLearningCenterAdviser?: Resolver<Maybe<ResolversTypes['LearningCenterAdviserResponse']>, ParentType, ContextType, RequireFields<MutationDisconnectLearningCenterAdviserArgs, 'adviserId' | 'learningCenterId'>>;
  inviteAdvisers?: Resolver<Maybe<ResolversTypes['InviteAdvisersResponse']>, ParentType, ContextType, RequireFields<MutationInviteAdvisersArgs, 'learningCenterId' | 'recipients' | 'resend'>>;
  inviteContentChannelOwner?: Resolver<Maybe<ResolversTypes['ContentChannelOwnerInvite']>, ParentType, ContextType, RequireFields<MutationInviteContentChannelOwnerArgs, 'contentChannelId' | 'recipientInput'>>;
  inviteContentCreator?: Resolver<Maybe<ResolversTypes['ContentCreatorInvite']>, ParentType, ContextType, RequireFields<MutationInviteContentCreatorArgs, 'contentChannelId' | 'recipientInput'>>;
  inviteLearners?: Resolver<Maybe<ResolversTypes['InviteLearnersResponse']>, ParentType, ContextType, RequireFields<MutationInviteLearnersArgs, 'adviserId' | 'recipients' | 'resend'>>;
  inviteLearningCenterOwner?: Resolver<Maybe<ResolversTypes['LearningCenterOwnerInvite']>, ParentType, ContextType, RequireFields<MutationInviteLearningCenterOwnerArgs, 'learningCenterId' | 'recipientInput'>>;
  inviteNewContentChannelOwner?: Resolver<Maybe<ResolversTypes['NewContentChannelOwnerInvite']>, ParentType, ContextType, RequireFields<MutationInviteNewContentChannelOwnerArgs, 'recipientInput'>>;
  inviteNewLearningCenterOwner?: Resolver<Maybe<ResolversTypes['NewLearningCenterOwnerInvite']>, ParentType, ContextType, RequireFields<MutationInviteNewLearningCenterOwnerArgs, 'recipientInput'>>;
  linkInviteAccept?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationLinkInviteAcceptArgs, 'inviteId' | 'learnerId'>>;
  linkInviteCreate?: Resolver<ResolversTypes['LinkInvite'], ParentType, ContextType, RequireFields<MutationLinkInviteCreateArgs, 'input'>>;
  markNotificationAsViewed?: Resolver<ResolversTypes['Notification'], ParentType, ContextType, RequireFields<MutationMarkNotificationAsViewedArgs, 'id'>>;
  reinviteMutation?: Resolver<ResolversTypes['Invite'], ParentType, ContextType, RequireFields<MutationReinviteMutationArgs, 'id'>>;
  rejectInvite?: Resolver<ResolversTypes['Invite'], ParentType, ContextType, RequireFields<MutationRejectInviteArgs, 'id'>>;
  reorderCourses?: Resolver<ResolversTypes['Series'], ParentType, ContextType, RequireFields<MutationReorderCoursesArgs, 'courseIdToMove' | 'seriesId'>>;
  sendCourseStateEvent?: Resolver<Maybe<ResolversTypes['CourseState']>, ParentType, ContextType, RequireFields<MutationSendCourseStateEventArgs, 'completion' | 'courseId' | 'learnerId'>>;
  seriesCreate?: Resolver<Maybe<ResolversTypes['Series']>, ParentType, ContextType, RequireFields<MutationSeriesCreateArgs, 'input'>>;
  seriesDelete?: Resolver<Maybe<ResolversTypes['Series']>, ParentType, ContextType, RequireFields<MutationSeriesDeleteArgs, 'seriesId'>>;
  seriesModify?: Resolver<Maybe<ResolversTypes['Series']>, ParentType, ContextType, RequireFields<MutationSeriesModifyArgs, 'input' | 'seriesId'>>;
  signUp?: Resolver<Maybe<ResolversTypes['SignUpUser']>, ParentType, ContextType, RequireFields<MutationSignUpArgs, 'data'>>;
  unsubscribeUser?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUnsubscribeUserArgs, 'id' | 'resubscribe' | 'secret'>>;
  updateContentChannel?: Resolver<Maybe<ResolversTypes['ContentChannel']>, ParentType, ContextType, RequireFields<MutationUpdateContentChannelArgs, 'data' | 'id'>>;
  updateCourse?: Resolver<Maybe<ResolversTypes['Course']>, ParentType, ContextType, RequireFields<MutationUpdateCourseArgs, 'courseId'>>;
  updateLearningCenter?: Resolver<Maybe<ResolversTypes['LearningCenter']>, ParentType, ContextType, RequireFields<MutationUpdateLearningCenterArgs, 'data' | 'id'>>;
  updateUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'data' | 'id'>>;
  uploadVideo?: Resolver<ResolversTypes['UploadVideoResponse'], ParentType, ContextType, RequireFields<MutationUploadVideoArgs, 'courseId' | 'filename'>>;
};

export type NewChannelVideoEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewChannelVideoEvent'] = ResolversParentTypes['NewChannelVideoEvent']> = {
  contentChannel?: Resolver<ResolversTypes['ContentChannel'], ParentType, ContextType>;
  course?: Resolver<ResolversTypes['Course'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewContentChannelOwnerInviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewContentChannelOwnerInvite'] = ResolversParentTypes['NewContentChannelOwnerInvite']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  sentAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InviteStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['InviteType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewLearningCenterEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewLearningCenterEvent'] = ResolversParentTypes['NewLearningCenterEvent']> = {
  learningCenter?: Resolver<ResolversTypes['LearningCenter'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewLearningCenterOwnerInviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewLearningCenterOwnerInvite'] = ResolversParentTypes['NewLearningCenterOwnerInvite']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  sentAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InviteStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['InviteType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewLiveChannelEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewLiveChannelEvent'] = ResolversParentTypes['NewLiveChannelEvent']> = {
  contentChannel?: Resolver<ResolversTypes['ContentChannel'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']> = {
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['NotificationData'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  recipient?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  viewed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationData'] = ResolversParentTypes['NotificationData']> = {
  __resolveType: TypeResolveFn<'AdviserConnectedToContentChannelEvent' | 'AdviserConnectedToLearningCenterEvent' | 'ChannelConnectedToLearningCenterEvent' | 'LearnerConnectedToAdviserEvent' | 'NewChannelVideoEvent' | 'NewLearningCenterEvent' | 'NewLiveChannelEvent' | 'NotificationInvite', ParentType, ContextType>;
};

export type NotificationInviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationInvite'] = ResolversParentTypes['NotificationInvite']> = {
  invite?: Resolver<ResolversTypes['Invite'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicUserInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicUserInfo'] = ResolversParentTypes['PublicUserInfo']> = {
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subscribedToEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  adviser?: Resolver<Maybe<ResolversTypes['Adviser']>, ParentType, ContextType, RequireFields<QueryAdviserArgs, never>>;
  contentChannel?: Resolver<Maybe<ResolversTypes['ContentChannel']>, ParentType, ContextType, RequireFields<QueryContentChannelArgs, never>>;
  contentChannelsAll?: Resolver<Maybe<Array<ResolversTypes['ContentChannel']>>, ParentType, ContextType>;
  contentChannelsBy?: Resolver<Maybe<Array<ResolversTypes['ContentChannel']>>, ParentType, ContextType, RequireFields<QueryContentChannelsByArgs, never>>;
  contentChannelsPublic?: Resolver<Maybe<Array<ResolversTypes['ContentChannel']>>, ParentType, ContextType>;
  course?: Resolver<Maybe<ResolversTypes['Course']>, ParentType, ContextType, RequireFields<QueryCourseArgs, 'id'>>;
  getLinkedInInfo?: Resolver<Maybe<ResolversTypes['LinkedInInfo']>, ParentType, ContextType, RequireFields<QueryGetLinkedInInfoArgs, 'code'>>;
  invite?: Resolver<Maybe<ResolversTypes['Invite']>, ParentType, ContextType, RequireFields<QueryInviteArgs, 'id'>>;
  invites?: Resolver<Array<ResolversTypes['Invite']>, ParentType, ContextType, RequireFields<QueryInvitesArgs, never>>;
  learner?: Resolver<Maybe<ResolversTypes['Learner']>, ParentType, ContextType, RequireFields<QueryLearnerArgs, 'userId'>>;
  learningCenter?: Resolver<Maybe<ResolversTypes['LearningCenter']>, ParentType, ContextType, RequireFields<QueryLearningCenterArgs, never>>;
  learningCenters?: Resolver<Array<ResolversTypes['LearningCenter']>, ParentType, ContextType>;
  linkInvite?: Resolver<Maybe<ResolversTypes['LinkInvite']>, ParentType, ContextType, RequireFields<QueryLinkInviteArgs, 'id'>>;
  series?: Resolver<Maybe<ResolversTypes['Series']>, ParentType, ContextType, RequireFields<QuerySeriesArgs, 'id'>>;
  unauthenticatedLinkInvite?: Resolver<Maybe<ResolversTypes['UnauthenticatedLinkInvite']>, ParentType, ContextType, RequireFields<QueryUnauthenticatedLinkInviteArgs, 'id'>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, 'id'>>;
  userInfo?: Resolver<Maybe<ResolversTypes['PublicUserInfo']>, ParentType, ContextType, RequireFields<QueryUserInfoArgs, 'id'>>;
};

export type SeriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Series'] = ResolversParentTypes['Series']> = {
  channel?: Resolver<ResolversTypes['ContentChannel'], ParentType, ContextType>;
  courseCompletionRate?: Resolver<ResolversTypes['CourseCompletionRate'], ParentType, ContextType, RequireFields<SeriesCourseCompletionRateArgs, never>>;
  courses?: Resolver<Array<ResolversTypes['Course']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignUpUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignUpUser'] = ResolversParentTypes['SignUpUser']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  identityProviderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRole'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnauthenticatedLinkInviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnauthenticatedLinkInvite'] = ResolversParentTypes['UnauthenticatedLinkInvite']> = {
  adviserId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  adviserName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contentChannelId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  contentChannelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  learningCenterId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  learningCenterName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UploadVideoResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UploadVideoResponse'] = ResolversParentTypes['UploadVideoResponse']> = {
  signedUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  adviser?: Resolver<Maybe<ResolversTypes['Adviser']>, ParentType, ContextType>;
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentChannelOwner?: Resolver<Maybe<ResolversTypes['ContentChannelOwner']>, ParentType, ContextType>;
  contentCreator?: Resolver<Maybe<ResolversTypes['ContentCreator']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  identityProviderId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  learner?: Resolver<Maybe<ResolversTypes['Learner']>, ParentType, ContextType>;
  learningCenterOwner?: Resolver<Maybe<ResolversTypes['LearningCenterOwner']>, ParentType, ContextType>;
  linkedinUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notifications?: Resolver<Array<ResolversTypes['Notification']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profileImage?: Resolver<ResolversTypes['Image'], ParentType, ContextType>;
  receivedInvites?: Resolver<Array<ResolversTypes['Invite']>, ParentType, ContextType>;
  recoveryEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['UserRoles']>, ParentType, ContextType>;
  sentInvites?: Resolver<Array<ResolversTypes['Invite']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>;
  subscribedToEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserRolesResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRoles'] = ResolversParentTypes['UserRoles']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRole'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Video'] = ResolversParentTypes['Video']> = {
  __resolveType: TypeResolveFn<'ExternalVideo' | 'HostedVideo', ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Adviser?: AdviserResolvers<ContextType>;
  AdviserConnectedToContentChannelEvent?: AdviserConnectedToContentChannelEventResolvers<ContextType>;
  AdviserConnectedToLearningCenterEvent?: AdviserConnectedToLearningCenterEventResolvers<ContextType>;
  AdviserInvite?: AdviserInviteResolvers<ContextType>;
  ChannelConnectedToLearningCenterEvent?: ChannelConnectedToLearningCenterEventResolvers<ContextType>;
  ContentChannel?: ContentChannelResolvers<ContextType>;
  ContentChannelAdviserResponse?: ContentChannelAdviserResponseResolvers<ContextType>;
  ContentChannelCreatorResponse?: ContentChannelCreatorResponseResolvers<ContextType>;
  ContentChannelLearningCenterResponse?: ContentChannelLearningCenterResponseResolvers<ContextType>;
  ContentChannelOwner?: ContentChannelOwnerResolvers<ContextType>;
  ContentChannelOwnerInvite?: ContentChannelOwnerInviteResolvers<ContextType>;
  ContentCreator?: ContentCreatorResolvers<ContextType>;
  ContentCreatorInvite?: ContentCreatorInviteResolvers<ContextType>;
  Course?: CourseResolvers<ContextType>;
  CourseCompletionRate?: CourseCompletionRateResolvers<ContextType>;
  CourseState?: CourseStateResolvers<ContextType>;
  CreateContentChannelFromInviteResponse?: CreateContentChannelFromInviteResponseResolvers<ContextType>;
  CreateLearningCenterFromInviteResponse?: CreateLearningCenterFromInviteResponseResolvers<ContextType>;
  Date?: GraphQLScalarType;
  Error?: ErrorResolvers<ContextType>;
  ExternalVideo?: ExternalVideoResolvers<ContextType>;
  HostedVideo?: HostedVideoResolvers<ContextType>;
  Image?: ImageResolvers<ContextType>;
  Invite?: InviteResolvers<ContextType>;
  InviteAdvisersResponse?: InviteAdvisersResponseResolvers<ContextType>;
  InviteLearnersResponse?: InviteLearnersResponseResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  Learner?: LearnerResolvers<ContextType>;
  LearnerAdviserResponse?: LearnerAdviserResponseResolvers<ContextType>;
  LearnerConnectedToAdviserEvent?: LearnerConnectedToAdviserEventResolvers<ContextType>;
  LearnerInvite?: LearnerInviteResolvers<ContextType>;
  LearningCenter?: LearningCenterResolvers<ContextType>;
  LearningCenterAdviserResponse?: LearningCenterAdviserResponseResolvers<ContextType>;
  LearningCenterOwner?: LearningCenterOwnerResolvers<ContextType>;
  LearningCenterOwnerInvite?: LearningCenterOwnerInviteResolvers<ContextType>;
  LinkInvite?: LinkInviteResolvers<ContextType>;
  LinkedInInfo?: LinkedInInfoResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NewChannelVideoEvent?: NewChannelVideoEventResolvers<ContextType>;
  NewContentChannelOwnerInvite?: NewContentChannelOwnerInviteResolvers<ContextType>;
  NewLearningCenterEvent?: NewLearningCenterEventResolvers<ContextType>;
  NewLearningCenterOwnerInvite?: NewLearningCenterOwnerInviteResolvers<ContextType>;
  NewLiveChannelEvent?: NewLiveChannelEventResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  NotificationData?: NotificationDataResolvers<ContextType>;
  NotificationInvite?: NotificationInviteResolvers<ContextType>;
  PublicUserInfo?: PublicUserInfoResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Series?: SeriesResolvers<ContextType>;
  SignUpUser?: SignUpUserResolvers<ContextType>;
  UnauthenticatedLinkInvite?: UnauthenticatedLinkInviteResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  UploadVideoResponse?: UploadVideoResponseResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserRoles?: UserRolesResolvers<ContextType>;
  Video?: VideoResolvers<ContextType>;
};


export type DeleteContentChannelMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteContentChannelMutation = { __typename?: 'Mutation', deleteContentChannel?: { __typename?: 'ContentChannel', id: string } | null | undefined };

export type UpdateChannelMutationMutationVariables = Exact<{
  id: Scalars['ID'];
  data: ContentChannelInput;
  logo?: InputMaybe<Scalars['Upload']>;
  backgroundImage?: InputMaybe<Scalars['Upload']>;
}>;


export type UpdateChannelMutationMutation = { __typename?: 'Mutation', updateContentChannel?: { __typename?: 'ContentChannel', id: string, name: string } | null | undefined };

export type InviteToChannelMutationVariables = Exact<{
  recipientInput: InviteRecipientInput;
  channelId: Scalars['ID'];
}>;


export type InviteToChannelMutation = { __typename?: 'Mutation', inviteContentChannelOwner?: { __typename?: 'ContentChannelOwnerInvite', id: string, status: InviteStatus, type: InviteType } | null | undefined };

export type InviteNewChannelOwnerMutationVariables = Exact<{
  recipientInput: InviteRecipientInput;
}>;


export type InviteNewChannelOwnerMutation = { __typename?: 'Mutation', inviteNewContentChannelOwner?: { __typename?: 'NewContentChannelOwnerInvite', id: string, status: InviteStatus, type: InviteType } | null | undefined };

export type CreateContentChannelMutationVariables = Exact<{
  data: ContentChannelInput;
  logo?: InputMaybe<Scalars['Upload']>;
  backgroundImage?: InputMaybe<Scalars['Upload']>;
}>;


export type CreateContentChannelMutation = { __typename?: 'Mutation', createContentChannel?: { __typename: 'ContentChannel', id: string, name: string } | null | undefined };

export type CreateCourseMutationVariables = Exact<{
  channelId: Scalars['ID'];
  data: CourseInput;
}>;


export type CreateCourseMutation = { __typename?: 'Mutation', createCourse?: { __typename: 'Course', id: string, description: string, video?: { __typename?: 'ExternalVideo' } | { __typename: 'HostedVideo', id?: string | null | undefined, status: CourseStatus, mp4Url?: string | null | undefined, hlsUrl?: string | null | undefined } | null | undefined } | null | undefined };

export type UploadVideoMutationVariables = Exact<{
  courseId: Scalars['ID'];
  filename: Scalars['String'];
}>;


export type UploadVideoMutation = { __typename?: 'Mutation', uploadVideo: { __typename?: 'UploadVideoResponse', signedUrl: string } };

export type VideoUploadStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type VideoUploadStatusQuery = { __typename?: 'Query', course?: { __typename: 'Course', id: string, video?: { __typename?: 'ExternalVideo' } | { __typename: 'HostedVideo', id?: string | null | undefined, status: CourseStatus, mp4Url?: string | null | undefined, hlsUrl?: string | null | undefined } | null | undefined } | null | undefined };

export type DeleteCourseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCourseMutation = { __typename?: 'Mutation', deleteCourse?: { __typename?: 'Course', id: string } | null | undefined };

export type CreateLearningCenterMutationVariables = Exact<{
  data: LearningCenterInput;
  logo?: InputMaybe<Scalars['Upload']>;
  backgroundImage?: InputMaybe<Scalars['Upload']>;
}>;


export type CreateLearningCenterMutation = { __typename?: 'Mutation', adminCreateLearningCenter?: { __typename?: 'LearningCenter', id: string, name: string } | null | undefined };

export type CreateContentChannelFromLearningCenterMutationVariables = Exact<{
  data: ContentChannelInput;
  logo?: InputMaybe<Scalars['Upload']>;
  backgroundImage?: InputMaybe<Scalars['Upload']>;
  owningLearningCenterId?: InputMaybe<Scalars['ID']>;
}>;


export type CreateContentChannelFromLearningCenterMutation = { __typename?: 'Mutation', createContentChannel?: { __typename: 'ContentChannel', id: string, name: string } | null | undefined };

export type DeleteLearningCenterMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLearningCenterMutation = { __typename?: 'Mutation', deleteLearningCenter?: { __typename?: 'LearningCenter', id: string } | null | undefined };

export type UpdateLearningCenterMutationMutationVariables = Exact<{
  id: Scalars['ID'];
  data: LearningCenterInput;
  logo?: InputMaybe<Scalars['Upload']>;
  backgroundImage?: InputMaybe<Scalars['Upload']>;
}>;


export type UpdateLearningCenterMutationMutation = { __typename?: 'Mutation', updateLearningCenter?: { __typename?: 'LearningCenter', id: string, name: string } | null | undefined };

export type InviteToLearningCenterMutationVariables = Exact<{
  recipientInput: InviteRecipientInput;
  learningCenterId: Scalars['ID'];
}>;


export type InviteToLearningCenterMutation = { __typename?: 'Mutation', inviteLearningCenterOwner?: { __typename?: 'LearningCenterOwnerInvite', id: string, status: InviteStatus, type: InviteType } | null | undefined };

export type InviteNewLearningCenterOwnerMutationVariables = Exact<{
  recipientInput: InviteRecipientInput;
}>;


export type InviteNewLearningCenterOwnerMutation = { __typename?: 'Mutation', inviteNewLearningCenterOwner?: { __typename?: 'NewLearningCenterOwnerInvite', id: string, status: InviteStatus, type: InviteType } | null | undefined };

export type UserQueryVariables = Exact<{
  userId: UserId;
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, roles: Array<{ __typename?: 'UserRoles', id: string, role: UserRole }>, profileImage: { __typename?: 'Image', url?: string | null | undefined } } | null | undefined };

export type ContentChannelQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ContentChannelQuery = { __typename?: 'Query', contentChannel?: { __typename: 'ContentChannel', id: string, name: string, courses?: Array<{ __typename: 'Course', id: string, title: string, views?: number | null | undefined, video?: { __typename?: 'ExternalVideo' } | { __typename: 'HostedVideo', id?: string | null | undefined, mp4Url?: string | null | undefined, hlsUrl?: string | null | undefined, status: CourseStatus } | null | undefined, courseCompletionRate: { __typename?: 'CourseCompletionRate', completionRate?: number | null | undefined } }> | null | undefined } | null | undefined };

export type ContentChannelsQueryVariables = Exact<{ [key: string]: never; }>;


export type ContentChannelsQuery = { __typename?: 'Query', contentChannelsAll?: Array<{ __typename: 'ContentChannel', id: string, name: string }> | null | undefined };

export type InvitesQueryVariables = Exact<{ [key: string]: never; }>;


export type InvitesQuery = { __typename?: 'Query', invites: Array<{ __typename?: 'AdviserInvite', id: string, status: InviteStatus, type: InviteType, recipient: { __typename?: 'User', email?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } } | { __typename?: 'ContentChannelOwnerInvite', id: string, status: InviteStatus, type: InviteType, recipient: { __typename?: 'User', email?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } } | { __typename?: 'ContentCreatorInvite', id: string, status: InviteStatus, type: InviteType, recipient: { __typename?: 'User', email?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } } | { __typename?: 'LearnerInvite', id: string, status: InviteStatus, type: InviteType, recipient: { __typename?: 'User', email?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } } | { __typename?: 'LearningCenterOwnerInvite', id: string, status: InviteStatus, type: InviteType, recipient: { __typename?: 'User', email?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } } | { __typename?: 'NewContentChannelOwnerInvite', id: string, status: InviteStatus, type: InviteType, recipient: { __typename?: 'User', email?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } } | { __typename?: 'NewLearningCenterOwnerInvite', id: string, status: InviteStatus, type: InviteType, recipient: { __typename?: 'User', email?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } }> };

export type LearningCenteresQueryVariables = Exact<{ [key: string]: never; }>;


export type LearningCenteresQuery = { __typename?: 'Query', learningCenters: Array<{ __typename?: 'LearningCenter', id: string, name: string, ownedContentChannels: Array<{ __typename?: 'ContentChannel', id: string }> }> };

export type UpdateUserMutationMutationVariables = Exact<{
  userId: UserId;
  data: UpdateUserInput;
  picture?: InputMaybe<Scalars['Upload']>;
}>;


export type UpdateUserMutationMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined } | null | undefined };
